var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import FormData from 'form-data';
import { dateToDateTimeString } from '../../utils';
import { uploadFile } from './uploadFile';
export const uploadFileList = (fileList, folderId, documentType, withDateTime) => Promise.all(fileList.map(({ name, stream, extension }, index) => __awaiter(void 0, void 0, void 0, function* () {
    const bodyFormData = new FormData();
    const documentation = documentType ? `${documentType}_` : '';
    const dateTime = withDateTime
        ? `_${dateToDateTimeString(new Date())}`
        : '';
    const indexing = index > 0 ? `_${index}` : '';
    const fileName = `${documentation}${name}${dateTime}${indexing}.${extension}`;
    bodyFormData.append('file', stream, fileName);
    bodyFormData.append('folderId', folderId);
    bodyFormData.append('options', JSON.stringify({
        access: 'PRIVATE',
        overwrite: true,
        duplicateValidationStrategy: 'NONE',
        duplicateValidationScope: 'EXACT_FOLDER',
    }));
    return yield uploadFile(bodyFormData);
})));
