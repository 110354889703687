var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
export const batchCreateDealNotes = (createNotes) => __awaiter(void 0, void 0, void 0, function* () {
    return axios.post('https://api.hubapi.com/crm/v3/objects/notes/batch/create', {
        inputs: createNotes.map((note) => ({
            properties: {
                hs_note_body: note.body,
            },
            associations: [
                {
                    to: { id: note.dealId },
                    types: [
                        {
                            associationCategory: 'HUBSPOT_DEFINED',
                            associationTypeId: 214,
                        },
                    ],
                },
            ],
        })),
    }, {
        headers: {
            Authorization: `Bearer ${process.env.HUBSPOT_ACCESS_TOKEN}`,
            'Content-Type': 'application/json',
        },
    });
});
