import { publicSupabase } from 'services/supabase/publicSupabaseClient';

interface LoginResult {
  success: boolean;
  error?: string;
}

export const loginWithPassword = async (
  email: string,
  password: string,
): Promise<LoginResult> => {
  const { error } = await publicSupabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error) {
    let message = error.message;
    if (message === 'Invalid login credentials') {
      message = 'Falsche Login-Daten';
    }
    return { success: false, error: message };
  }

  return { success: true };
};

export const logout = () => publicSupabase.auth.signOut();
