import axios from 'axios';
export const subscribeContactToCommunication = (emailAddress, subscriptionId, legalBasis, legalBasisExplanation) => axios.post(`https://api-eu1.hubapi.com/communication-preferences/v4/statuses/${emailAddress}`, {
    subscriptionId,
    statusState: 'SUBSCRIBED',
    legalBasis,
    legalBasisExplanation,
    channel: 'EMAIL',
}, {
    headers: {
        Authorization: `Bearer ${process.env.HUBSPOT_ACCESS_TOKEN}`,
        'Content-Type': 'application/json',
    },
});
