import React from 'react';

export const MessageBox: React.FC<{ messages: string[] }> = ({ messages }) => {
  return (
    <div className="flex flex-col items-center p-3 md:p-12 rounded-md border border-solid border-auxiliary-100 italic text-h5 text-center">
      {messages.map((it, n) => (
        <p key={`message-${n}`}>{it}</p>
      ))}
    </div>
  );
};
