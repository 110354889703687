import { hubspotClient } from '../../client';
import { batchReadDealProperties } from './batchReadDealProperties';
export const getDealsByContactId = (contactId, properties) => hubspotClient.crm.associations.v4.batchApi
    .getPage('contact', 'deal', {
    inputs: [{ id: contactId }],
})
    .then(({ results }) => results.length ? results[0].to.map((to) => `${to.toObjectId}`) : undefined)
    .then((deals) => (deals === null || deals === void 0 ? void 0 : deals.length)
    ? batchReadDealProperties(deals, properties || [])
    : undefined);
