var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { hubspotClient } from '../../client';
import { getOwners } from './getOwners';
export const getOwnersWithUserInformation = () => __awaiter(void 0, void 0, void 0, function* () {
    const owners = yield getOwners();
    const userInformation = yield hubspotClient.cms.hubdb.rowsApi.getTableRows('user_information');
    const userInformationByEmail = Object.fromEntries(userInformation.results.map(({ values }) => [values.email, values]));
    return owners.map((owner) => (Object.assign(Object.assign({}, owner), (owner.email && userInformationByEmail[owner.email]
        ? userInformationByEmail[owner.email]
        : {}))));
});
