var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { hubspotClient } from '../../client';
export const getContactByEmail = (email, properties) => __awaiter(void 0, void 0, void 0, function* () {
    const contactSearchResponse = yield hubspotClient.crm.contacts.searchApi.doSearch({
        filterGroups: [
            {
                filters: [
                    {
                        value: email,
                        propertyName: 'email',
                        operator: 'EQ',
                    },
                ],
            },
        ],
        sorts: [],
        properties: properties !== null && properties !== void 0 ? properties : [
            'address',
            'phone',
            'email',
            'firstname',
            'lastname',
            'city',
            'customer_city_and_postal_code',
            'zip',
            'province',
            'country',
        ],
        limit: 1,
        after: 0,
    });
    if (!contactSearchResponse.results || !contactSearchResponse.results.length) {
        return undefined;
    }
    const contact = contactSearchResponse.results[0];
    return Object.assign({ id: contact.id }, contact.properties);
});
