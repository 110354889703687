var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getNoteById } from '../note/getNoteById';
import { getFileById } from './getFileById';
export const getFilesByNoteId = (noteId) => __awaiter(void 0, void 0, void 0, function* () {
    const note = yield getNoteById(noteId);
    if (!note.properties.hs_attachment_ids) {
        return [];
    }
    const files = yield Promise.all(note.properties.hs_attachment_ids.split(';').map((attachmentId) => {
        return getFileById(attachmentId).catch(() => null);
    }));
    return files.filter((file) => !!file);
});
