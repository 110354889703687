var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { hubspotClient } from '../../client';
import { extendPriceList } from './common';
export const IdSeparator = ';';
export var PortfolioProductType;
(function (PortfolioProductType) {
    PortfolioProductType["PANEL"] = "panel";
    PortfolioProductType["SUBSTRUCTURE"] = "substructure";
    PortfolioProductType["INVERTER"] = "inverter";
    PortfolioProductType["BATTERY"] = "battery";
    PortfolioProductType["SERVICE"] = "service";
    PortfolioProductType["WORK"] = "work";
    PortfolioProductType["EU"] = "eu";
    PortfolioProductType["PV"] = "PV";
    PortfolioProductType["ES"] = "ES";
})(PortfolioProductType || (PortfolioProductType = {}));
export const getPriceTable = ({ useDraft, } = {}) => __awaiter(void 0, void 0, void 0, function* () {
    const tableId = 'portfolio_price_table';
    const columns = [
        'version',
        'type',
        'id',
        'info',
        'data',
        'price',
        'price_excluding_margin',
    ];
    const priceTableRaw = useDraft
        ? yield hubspotClient.cms.hubdb.rowsApi.readDraftTableRows(tableId, undefined, undefined, 10000, columns)
        : yield hubspotClient.cms.hubdb.rowsApi.getTableRows(tableId, undefined, undefined, 10000, columns);
    const priceTable = priceTableRaw.results.reduce((priceTable, currentRow) => {
        var _a, _b;
        const priceInfo = {
            type: (_a = currentRow.values.type) === null || _a === void 0 ? void 0 : _a.name,
            id: currentRow.values.id,
            info: currentRow.values.info,
            data: currentRow.values.data,
            price: Number(currentRow.values.price),
            version: currentRow.values.version,
            versions: [],
            priceWithoutMargin: currentRow.values.price_excluding_margin &&
                Number(currentRow.values.price_excluding_margin),
        };
        priceTable[priceInfo.type] = extendPriceList(priceInfo.id, (_b = priceTable[priceInfo.type]) !== null && _b !== void 0 ? _b : {}, priceInfo);
        return priceTable;
    }, {});
    return priceTable;
});
export const getPriceInfo = (type, id, priceTable) => { var _a; return (_a = priceTable === null || priceTable === void 0 ? void 0 : priceTable[type]) === null || _a === void 0 ? void 0 : _a[id]; };
