import {
  checkPassedDealStage,
  DealStages,
} from '@hallosonne/hubspot-api-client';
import { SectionType } from 'api/types';
import { isDateNextDayOrLater } from 'utils/isDateNextDayOrLater';

export const getSectionForPVInstallation = (
  dealStage: string,
  selectedInstallationDate: Date | undefined,
) => {
  if (
    checkPassedDealStage(dealStage, DealStages.STAGE_7_1) &&
    !checkPassedDealStage(dealStage, DealStages.STAGE_7_3)
  ) {
    if (!selectedInstallationDate) {
      return SectionType.TO_PLAN;
    } else if (isDateNextDayOrLater(selectedInstallationDate)) {
      return SectionType.PLANNED;
    } else {
      return SectionType.IN_PROGRESS;
    }
  } else if (checkPassedDealStage(dealStage, DealStages.STAGE_7_3)) {
    return SectionType.COMPLETED;
  }

  return SectionType.INDETERMINATE;
};
