var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { hubspotClient } from '../../client';
export const getAllDealsInStagesWithProperties = (stages, properties) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const limit = 100;
            let deals = [];
            let after = 0;
            do {
                const apiResponse = yield hubspotClient.crm.deals.searchApi.doSearch({
                    filterGroups: [
                        {
                            filters: [
                                {
                                    propertyName: 'dealstage',
                                    operator: 'IN',
                                    values: stages,
                                },
                            ],
                        },
                    ],
                    properties,
                    limit,
                    after,
                    sorts: [],
                });
                deals = deals.concat(apiResponse.results);
                after = Number((_b = (_a = apiResponse.paging) === null || _a === void 0 ? void 0 : _a.next) === null || _b === void 0 ? void 0 : _b.after);
                yield new Promise((resolve) => setTimeout(resolve, 200));
            } while (after);
            return deals;
        });
    })();
});
