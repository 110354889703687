import { axiosClient } from 'utils/api/axios-client';

export interface DBDocumentStatus {
  id: string;
  label: string;
  color: 'error' | 'success';
}

export interface DBDocumentMetaData {
  id?: string;
  hubspotId: string;
  dealId: string;
  status: DBDocumentStatus;
}

export interface PostDocumentMetaData
  extends Omit<DBDocumentMetaData, 'status'> {
  statusId: string;
}

export const getDocumentStatuses = async () => {
  const axios = await axiosClient();
  const response = await axios.get<DBDocumentStatus[]>(
    '/api/document-meta/statuses',
  );
  return response.data;
};

export const postDocumentStatus = async (
  documentMeta: PostDocumentMetaData,
) => {
  const axios = await axiosClient();
  return axios.post<PostDocumentMetaData>('/api/document-meta', documentMeta);
};

export const updateDocumentStatus = async (id: string, status: string) => {
  const axios = await axiosClient();
  return axios.patch<{ status: string }>(`/api/document-meta/${id}`, {
    status,
  });
};
