import dayjs from 'dayjs';
export const dateToHubspotDateString = (date) => dayjs(date).format('YYYY-MM-DD');
export const dateToDateTimeString = (date) => dayjs(date).format('YYYY-MM-DD_HH-mm-ss');
export const fileToFileData = (file, dealId, metadata) => {
    var _a;
    return ({
        id: file.id,
        expiresAt: dayjs(file.expiresAt).format('DD.MM.YYYY HH:mm'),
        url: file.url,
        name: file.name,
        fileName: `${dealId}_${file.name}.${file.extension}`,
        type: file.type,
        size: file.size,
        status: (_a = metadata === null || metadata === void 0 ? void 0 : metadata.find((meta) => meta.hubspot_id === file.id)) === null || _a === void 0 ? void 0 : _a.status,
    });
};
export const parseAdditionalEUComponents = (additionalComponents) => additionalComponents
    .replace(/(\r\n|\n|\r)/gm, '')
    .split(';')
    .filter((item) => item !== '')
    .map((component) => {
    const [name, price] = component.split(',');
    return {
        name: name.trim(),
        price: Number(price.trim()),
    };
});
