import { Body } from '@hallosonne/components';
import classNames from 'classnames';
import { FC } from 'react';

interface DividerProps {
  className?: string;
  color?: 'default' | 'secondary';
  text?: string;
}

export const Divider: FC<DividerProps> = ({
  className,
  color = 'default',
  text,
}) => {
  return (
    <span className="flex flex-row items-center w-full">
      {text && (
        <span className="min-w-fit pr-3">
          <Body size="caption">{text}</Body>
        </span>
      )}
      <hr
        className={classNames(className, 'border-t w-full', {
          'border-auxiliary-100': color === 'default',
          'border-secondary-200': color === 'secondary',
        })}
      />
    </span>
  );
};
