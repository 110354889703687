import { dateToHubspotDateString } from '@hallosonne/hubspot-api-client';
import { AxiosResponse } from 'axios';

import { axiosClient } from '../utils/api/axios-client';

export const updateInstallationDate = async (
  url: string,
  installationDate: Date,
  oldInstallationDate?: Date,
  changeReason?: string,
  installationTeam?: string,
): Promise<string> => {
  const axiosInstance = await axiosClient();
  const date: AxiosResponse<string> = await axiosInstance.patch(url, {
    installationDate: dateToHubspotDateString(installationDate),
    oldInstallationDate: oldInstallationDate
      ? dateToHubspotDateString(oldInstallationDate)
      : undefined,
    changeReason,
    installationTeam,
  });

  return date.data;
};
