import { hubspotClient } from '../../client';
/**
 * Retrieves Contact IDs associated with given Deal IDs from HubSpot CRM.
 * @param dealIds - An array of Deal IDs for which to retrieve associated Contact IDs.
 * @returns A promise that resolves to a map where each key is a Deal ID and the corresponding value is the associated Contact ID, or `null` if no association is found.
 */
export const batchGetContactIdsByDealIds = (dealIds) => hubspotClient.crm.associations.v4.batchApi
    .getPage('deal', 'contact', {
    inputs: dealIds.map((id) => ({ id })),
})
    .then(({ results }) => new Map(dealIds.map((id) => {
    const result = results.find((res) => res._from.id === id);
    if (result && result.to.length) {
        const [contactId] = result.to;
        return [result._from.id, `${contactId.toObjectId}`];
    }
    return [id, null];
})));
