import axios from 'axios';
export const createTask = (taskProperties) => axios.post('https://api.hubapi.com/crm/v3/objects/tasks', {
    properties: {
        hs_timestamp: taskProperties.dueDate.toISOString(),
        hs_task_subject: taskProperties.title,
        hs_task_status: 'NOT_STARTED',
        hs_task_priority: taskProperties.priority || 'MEDIUM',
        hs_task_type: taskProperties.type || 'TODO',
        hubspot_owner_id: taskProperties.ownerId,
    },
    associations: taskProperties.associations || [
        {
            to: { id: taskProperties.dealId },
            types: [
                {
                    associationCategory: 'HUBSPOT_DEFINED',
                    associationTypeId: 216,
                },
            ],
        },
    ],
}, {
    headers: {
        Authorization: `Bearer ${process.env.HUBSPOT_ACCESS_TOKEN}`,
        'Content-Type': 'application/json',
    },
});
