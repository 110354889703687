import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import { publicSupabase } from 'services/supabase/publicSupabaseClient';

export const axiosClient = async (): Promise<AxiosInstance> => {
  const token = await getAuthToken();
  const client = axios.create({
    headers: {
      'x-supabase-auth': token,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  axiosRetry(client, {
    retries: 2,
    retryDelay: retryCount => {
      return retryCount * 3000;
    },
  });
  return client;
};

const getAuthToken = async (): Promise<string> => {
  const auth = await publicSupabase.auth.getSession();
  const token = auth.data.session?.access_token;
  if (!token) {
    console.log(`could not get auth: ${auth}`);
    return Promise.reject();
  }

  return Promise.resolve(token);
};
