import { hubspotClient } from '../../client';
export const getContactByDealId = (dealId, properties) => hubspotClient.crm.associations.v4.batchApi
    .getPage('deal', 'contact', {
    inputs: [{ id: dealId }],
})
    .then(({ results }) => results.length ? `${results[0].to[0].toObjectId}` : undefined)
    .then((contactId) => !!contactId
    ? hubspotClient.crm.contacts.basicApi.getById(contactId, properties)
    : undefined)
    .then((contact) => !!contact ? Object.assign({ id: contact.id }, contact.properties) : undefined);
