import { hubspotClient } from '../../client';
import { set } from 'date-fns';
export const getDiscountCodeTable = (options) => ((options === null || options === void 0 ? void 0 : options.useDraft)
    ? hubspotClient.cms.hubdb.rowsApi.readDraftTableRows('discount_code_table', ['expiration_date'])
    : hubspotClient.cms.hubdb.rowsApi.getTableRows('discount_code_table', [
        'expiration_date',
    ])).then(({ results }) => results.map((row) => ({
    code: row.values.code,
    type: row.values.type.name,
    label: row.values.label,
    discountId: row.values.discount_id,
    expirationDate: row.values.expiration_date &&
        set(new Date(row.values.expiration_date), {
            hours: 24, // Set date to End of day
        }),
    discountPercent: row.values.discount_percent,
})));
