var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import DOMPurify from 'dompurify';
export var InstallerNoteBody;
(function (InstallerNoteBody) {
    InstallerNoteBody["NOTE"] = "[INSTALLER] Note";
    InstallerNoteBody["FOTOS"] = "[INSTALLER] Fotos";
    InstallerNoteBody["PLANNING"] = "[INSTALLER] Technische Planung";
    InstallerNoteBody["DOCUMENTATION"] = "[INSTALLER] Documentation";
})(InstallerNoteBody || (InstallerNoteBody = {}));
export const formatInstallerNoteText = (noteText) => {
    if (!noteText)
        return '';
    return DOMPurify.sanitize(noteText
        .replaceAll('[INSTALLER]Note', '')
        .replaceAll('[INSTALLER] Note', ''), {
        USE_PROFILES: { html: true },
    });
};
export const notesToInstallerNotes = (notes) => __awaiter(void 0, void 0, void 0, function* () {
    const hubspotNotesResponse = {
        notes: [],
        technicalPlanning: [],
        photos: [],
        documentation: [],
    };
    notes.forEach((note) => __awaiter(void 0, void 0, void 0, function* () {
        const noteBody = note.properties.hs_note_body;
        if (!noteBody) {
            return;
        }
        else if (noteBody.includes('[INSTALLER] Note') ||
            noteBody.includes('[INSTALLER]Note')) {
            hubspotNotesResponse.notes.push(note);
        }
        else if (noteBody.includes('[INSTALLER] Technische') ||
            noteBody.includes('[INSTALLER]Technische')) {
            hubspotNotesResponse.technicalPlanning.push(note);
        }
        else if (noteBody.includes('[INSTALLER] Fotos') ||
            noteBody.includes('[INSTALLER]Fotos')) {
            hubspotNotesResponse.photos.push(note);
        }
        else if (noteBody.includes('[INSTALLER] Documentation') ||
            noteBody.includes('[INSTALLER]Documentation')) {
            hubspotNotesResponse.documentation.push(note);
        }
    }));
    return hubspotNotesResponse;
});
