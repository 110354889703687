var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { hubspotClient } from '../../client';
import { extendPriceTable, ProductType, } from './common';
export const getPVPriceTable = () => __awaiter(void 0, void 0, void 0, function* () {
    const priceTableRaw = yield hubspotClient.cms.hubdb.rowsApi.getTableRows('pv_price_table', undefined, undefined, undefined, ['version', 'panels', 'type', 'price']);
    const priceTable = priceTableRaw.results.reduce((priceTable, currentRow) => {
        const priceInfo = {
            product: ProductType.PV,
            panels: Number(currentRow.values.panels),
            price: Number(currentRow.values.price),
            type: currentRow.values.type,
            version: currentRow.values.version,
            versions: [],
        };
        return extendPriceTable(priceTable, priceInfo);
    }, {
        rent: {},
        purchase: {},
    });
    return priceTable;
});
export const getPVPriceInfo = (numberOfSolarPanels, contractType, priceTable) => { var _a; return (_a = priceTable === null || priceTable === void 0 ? void 0 : priceTable[contractType]) === null || _a === void 0 ? void 0 : _a[numberOfSolarPanels]; };
