var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { updateNoteProperties } from './updateNoteProperties';
export const addFilesToNote = (uploadedFileIds, noteData) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const noteAttachments = (_c = (_b = (_a = noteData === null || noteData === void 0 ? void 0 : noteData.properties) === null || _a === void 0 ? void 0 : _a.hs_attachment_ids) === null || _b === void 0 ? void 0 : _b.split(';')) !== null && _c !== void 0 ? _c : [];
    const allNoteAttachments = noteAttachments.concat(uploadedFileIds);
    return yield updateNoteProperties(noteData.id, {
        hs_attachment_ids: allNoteAttachments.join(';'),
    });
});
