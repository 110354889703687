var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { hubspotClient } from '../../client';
import { getNoteById, updateNoteProperties } from '../note';
export const deleteFile = (id, noteId) => __awaiter(void 0, void 0, void 0, function* () {
    const note = yield getNoteById(noteId);
    if (note) {
        const attachmentIds = note.properties.hs_attachment_ids.split(';');
        const updatedNoteProperties = {
            hs_attachment_ids: attachmentIds
                .filter((attachmentId) => attachmentId !== id)
                .join(';'),
        };
        yield updateNoteProperties(note.id, updatedNoteProperties);
        return hubspotClient.files.filesApi.archive(id);
    }
    throw Error("Attachment isn't linked to a Note");
});
