import getConfig from 'next/config';
import { configObject } from 'next.config.js';

export enum Environment {
  Production = 'production',
  Development = 'development',
}

export type Config = typeof configObject;

const config = getConfig() as Config;

export const IS_IN_PROD =
  config.publicRuntimeConfig.environment === 'production';

export default config;
