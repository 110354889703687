import { DBCompany, Installation, SectionType } from 'api/types';
import { DBDocumentStatus } from 'services/supabase/adapters/documentMeta';
import { DBUser } from 'services/supabase/adapters/typeUser';
import { create } from 'zustand';

interface InstallerState {
  user: DBUser | undefined;
  companies: DBCompany[];
  selectedCompany: DBCompany | undefined;
  installations: Installation[];
  documentStatuses?: DBDocumentStatus[];
  documentStatusChanges: { fileId: string; statusId: string }[];
  dealStages: string[];
  setUser: (user: DBUser | undefined) => void;
  setInstallations: (installations: Installation[]) => void;
  setCompanies: (companies: DBCompany[]) => void;
  setSelectedCompany: (company: DBCompany | undefined) => void;
  setDocumentStatuses: (statuses: DBDocumentStatus[]) => void;
  addDocumentStatusChange: (fileId: string, statusId: string) => void;
  setDealStages: (dealStages: string[]) => void;
  updateStoreDealProperties: (
    dealId: string,
    properties: Partial<Installation>,
  ) => void;
}

const useStore = create<InstallerState>(set => ({
  user: undefined,
  installations: [],
  companies: [],
  selectedCompany: undefined,
  documentStatuses: undefined,
  documentStatusChanges: [],
  dealStages: [],
  setInstallations: installations =>
    set(state => ({
      ...state,
      installations,
    })),
  setCompanies: companies =>
    set(state => {
      return {
        ...state,
        companies,
      };
    }),
  setUser: user =>
    set(state => {
      return {
        ...state,
        user,
      };
    }),
  setSelectedCompany: selectedCompany =>
    set(state => {
      return {
        ...state,
        selectedCompany,
      };
    }),
  setDocumentStatuses: documentStatuses =>
    set(state => ({ ...state, documentStatuses })),
  addDocumentStatusChange: (fileId: string, statusId: string) =>
    set(state => ({
      ...state,
      documentStatusChanges: [
        ...state.documentStatusChanges,
        { fileId, statusId },
      ],
    })),
  setDealStages: dealStages => set(state => ({ ...state, dealStages })),
  updateStoreDealProperties: (dealId, properties) =>
    set(state => ({
      ...state,
      installations: state.installations.map((item: Installation) =>
        item.dealId === dealId ? { ...item, ...properties } : item,
      ),
    })),
}));

const sortInstallationsDesc = (a: Installation, b: Installation): number => {
  if (a.selectedInstallationDateRaw && b.selectedInstallationDateRaw) {
    return (
      b.selectedInstallationDateRaw.getTime() -
      a.selectedInstallationDateRaw.getTime()
    );
  } else if (a.selectedInstallationDateRaw) {
    return -1;
  } else if (b.selectedInstallationDateRaw) {
    return 1;
  }
  return 0;
};

const sortInstallationsAsc = (a: Installation, b: Installation): number => {
  return sortInstallationsDesc(b, a);
};

const isInstallationVisible = (
  installation: Installation,
  company: DBCompany | undefined,
): boolean => {
  if (company === undefined) {
    return true;
  }

  if (installation.roofer?.id) {
    return installation.roofer.id === company.id;
  }

  return false;
};

const selectVisibleInstallations = (store: InstallerState): Installation[] =>
  store.installations.filter(installation =>
    isInstallationVisible(installation, store.selectedCompany),
  );

const selectVisibleInstallationsBySectionAndSort = (
  store: InstallerState,
  sectionType: SectionType,
  sorting: (a: Installation, b: Installation) => number,
): Installation[] =>
  selectVisibleInstallations(store)
    .filter(({ section }) => section === sectionType)
    .sort(sorting);

const selectToPlanInstallations = (store: InstallerState): Installation[] =>
  selectVisibleInstallationsBySectionAndSort(
    store,
    SectionType.TO_PLAN,
    sortInstallationsAsc,
  );

const selectPlannedInstallations = (store: InstallerState): Installation[] =>
  selectVisibleInstallationsBySectionAndSort(
    store,
    SectionType.PLANNED,
    sortInstallationsAsc,
  );

const selectInProgressInstallations = (store: InstallerState): Installation[] =>
  selectVisibleInstallationsBySectionAndSort(
    store,
    SectionType.IN_PROGRESS,
    sortInstallationsAsc,
  );

const selectCompletedInstallations = (store: InstallerState): Installation[] =>
  selectVisibleInstallationsBySectionAndSort(
    store,
    SectionType.COMPLETED,
    sortInstallationsDesc,
  );

export {
  selectCompletedInstallations,
  selectInProgressInstallations,
  selectPlannedInstallations,
  selectToPlanInstallations,
  useStore,
};
