var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { pickBy } from 'lodash';
import { hubspotClient } from '../../client';
export const getContactProperties = (contactId, contactProperties) => __awaiter(void 0, void 0, void 0, function* () {
    const contact = yield hubspotClient.crm.contacts.basicApi.getById(contactId, contactProperties);
    return Object.assign({ id: contactProperties.includes('id') ? contact.id : undefined }, pickBy(contact.properties, (_value, key) => contactProperties.includes(key)));
});
