export const gridOperators = [
    {
        label: 'AAE Wasserkraft Gesellschaft',
        value: 'AAE Wasserkraft Gesellschaft',
    },
    {
        label: 'Alfenzwerke Elektrizitätserzugung GmbH',
        value: 'Alfenzwerke Elektrizitätserzugung GmbH',
    },
    { label: 'Andreas Braunstein', value: 'Andreas Braunstein' },
    { label: 'Anton Kittel Mühle Plaika', value: 'Anton Kittel Mühle Plaika' },
    { label: 'Bad Gleichenberger Energie', value: 'Bad Gleichenberger Energie' },
    { label: 'E Werk Stubenberg', value: 'E Werk Stubenberg' },
    { label: 'E-Werk Altenfelden', value: 'E-Werk Altenfelden' },
    {
        label: 'E-Werk der Marktgemeinde Unzmarkt-Frauenberg',
        value: 'E-Werk der Marktgemeinde Unzmarkt-Frauenberg',
    },
    { label: 'E-Werk Dietrichschlag', value: 'E-Werk Dietrichschlag' },
    { label: 'E-Werk Ebner', value: 'E-Werk Ebner' },
    { label: 'E-Werk Gleinstätten', value: 'E-Werk Gleinstätten' },
    {
        label: 'E-Werk Gösting Stromversorgungs',
        value: 'E-Werk Gösting Stromversorgungs',
    },
    { label: 'E-Werk Kötschach', value: 'E-Werk Kötschach' },
    {
        label: 'E-Werk Neudau, Kottulinsky KG',
        value: 'E-Werk Neudau, Kottulinsky KG',
    },
    { label: 'E-Werk Ingrid Reinisch', value: 'E-Werk Ingrid Reinisch' },
    { label: 'E-Werk Piwetz', value: 'E-Werk Piwetz' },
    { label: 'E-Werk Ranklleiten', value: 'E-Werk Ranklleiten' },
    {
        label: 'E-Werk Redlmühle Bernhard Drack',
        value: 'E-Werk Redlmühle Bernhard Drack',
    },
    {
        label: 'E-Werk Sarmingstein Ing. H. Engelmann & Co KG',
        value: 'E-Werk Sarmingstein Ing. H. Engelmann & Co KG',
    },
    { label: 'E-Werk Schwaighofer', value: 'E-Werk Schwaighofer' },
    { label: 'E-Werk Sigl', value: 'E-Werk Sigl' },
    { label: 'E-Werk Stadler GmbH', value: 'E-Werk Stadler GmbH' },
    { label: 'E-Werk Stubenberg', value: 'E-Werk Stubenberg' },
    {
        label: 'E-Genossenschaft Laintal Reg. Gen.m.b.H',
        value: 'Elektrizitätsgenossenschaft Laintal registrierte Genossenschaft mit beschränkter Haftung',
    },
    { label: 'Ebner Strom Gmbh', value: 'Ebner Strom Gmbh' },
    {
        label: 'Elektrizitätswerk Bad Hofgastein',
        value: 'Elektrizitätswerk Bad Hofgastein',
    },
    {
        label: 'Elektrizitätswerk Clam Carl-Philip Clam-Martinic',
        value: 'Elektrizitätswerk Clam Carl-Philip Clam-Martinic',
    },
    {
        label: 'Elektrizitätswerk der Gemeinde Gries am Brenner',
        value: 'Elektrizitätswerk der Gemeinde Gries am Brenner',
    },
    {
        label: 'Elektrizitätswerk der Stadtgemeinde Kindberg',
        value: 'Elektrizitätswerk der Stadtgemeinde Kindberg',
    },
    {
        label: 'Elektrizitätswerk Fernitz Ing. Franz Purkarthofer',
        value: 'Elektrizitätswerk Fernitz Ing. Franz Purkarthofer',
    },
    { label: 'Elektrizitätswerk Gröbming', value: 'Elektrizitätswerk Gröbming' },
    { label: 'Elektrizitätswerk Mariahof', value: 'Elektrizitätswerk Mariahof' },
    { label: 'Elektrizitätswerk Mürzsteg', value: 'Elektrizitätswerk Mürzsteg' },
    { label: 'Elektrizitätswerk Perg', value: 'Elektrizitätswerk Perg' },
    { label: 'Elektrizitätswerk Prantl', value: 'Elektrizitätswerk Prantl' },
    { label: 'Elektrizitätswerk Winkler', value: 'Elektrizitätswerk Winkler' },
    {
        label: 'Elektrizitätswerke Bad Radkersburg',
        value: 'Elektrizitätswerke Bad Radkersburg',
    },
    {
        label: 'Elektrizitätswerke Eisenhuber',
        value: 'Elektrizitätswerke Eisenhuber',
    },
    {
        label: 'Elektrizitätswerke Frastanz Gesellschaft',
        value: 'Elektrizitätswerke Frastanz Gesellschaft',
    },
    { label: 'Elektrizitätswerke Reutte', value: 'Elektrizitätswerke Reutte' },
    {
        label: 'Elektrogenossenschaft Weerberg',
        value: 'Elektrogenossenschaft Weerberg',
    },
    { label: 'Elektrowerk Assling', value: 'Elektrowerk Assling' },
    { label: 'Elektrowerk Schöder', value: 'Elektrowerk Schöder' },
    {
        label: 'Elektrowerkgenossenschaft Hopfgarten',
        value: 'Elektrowerkgenossenschaft Hopfgarten',
    },
    { label: 'Energie Güssing', value: 'Energie Güssing' },
    { label: 'Energie Klagenfurt', value: 'Energie Klagenfurt' },
    { label: 'Energie Ried', value: 'Energie Ried' },
    { label: 'Energienetze Steiermark', value: 'energienetze_steiermark' },
    { label: 'Energieversorgungs GmbH', value: 'Energieversorgungs GmbH' },
    {
        label: 'Energieversorgung Kleinwalsertal',
        value: 'Energieversorgung Kleinwalsertal',
    },
    {
        label: 'Energieversorgungsunternehmen der Florian Lugitsch Gruppe',
        value: 'Energieversorgungsunternehmen der Florian Lugitsch Gruppe',
    },
    {
        label: 'Envesta Energie- und Dienstleistungs',
        value: 'Envesta Energie- und Dienstleistungs',
    },
    {
        label: 'EVU der Marktgemeinde Niklasdorf',
        value: 'EVU der Marktgemeinde Niklasdorf',
    },
    {
        label: 'EVU der Stadtgemeinde Mureck',
        value: 'EVU der Stadtgemeinde Mureck',
    },
    { label: 'EVU Gerald Mathe', value: 'EVU Gerald Mathe' },
    {
        label: 'EWA Energie- und Wirtschaftsbetriebe der Gemeinde St. Anton',
        value: 'EWA Energie- und Wirtschaftsbetriebe der Gemeinde St. Anton',
    },
    { label: 'eww ag', value: 'eww ag' },
    {
        label: 'Feistritzthaler Elektrizitätswerk',
        value: 'Feistritzthaler Elektrizitätswerk',
    },
    { label: 'Feistritzwerke-STEWEAG', value: 'Feistritzwerke-STEWEAG' },
    {
        label: 'Forstverwaltung Neuhaus Alpl Kraftwerksbetrieb',
        value: 'Forstverwaltung Neuhaus Alpl Kraftwerksbetrieb',
    },
    { label: 'Forstverwaltung Seehof', value: 'Forstverwaltung Seehof' },
    { label: 'Gemeinde Kematen', value: 'Gemeinde Kematen' },
    { label: 'Gertraud Schafler', value: 'Gertraud Schafler' },
    { label: 'Getzner, Mutter & Cie', value: 'Getzner, Mutter & Cie' },
    { label: 'HALLAG Kommunal', value: 'HALLAG Kommunal' },
    { label: 'Heinrich Polsterer', value: 'Heinrich Polsterer' },
    { label: 'Ing. Peter Böhm', value: 'Ing. Peter Böhm' },
    {
        label: 'Innsbrucker Kommunalbetriebe',
        value: 'Innsbrucker Kommunalbetriebe',
    },
    { label: 'Joh. Pengg', value: 'Joh. Pengg' },
    { label: 'K.u.F. Drack GmbH & Co KG', value: 'K.u.F. Drack GmbH & Co KG' },
    { label: 'KARLSTROM e.U.', value: 'KARLSTROM e.U.' },
    { label: 'Klausbauer Wasser Kraft', value: 'Klausbauer Wasser Kraft' },
    { label: 'KNG-Kärnten Netz', value: 'KNG-Kärnten Netz' },
    { label: 'Kneidinger 1880 GmbH', value: 'Kneidinger 1880 GmbH' },
    {
        label: 'Kommunalbetriebe Hopfgarten',
        value: 'Kommunalbetriebe Hopfgarten',
    },
    { label: 'Kommunalbetriebe Rinn', value: 'Kommunalbetriebe Rinn' },
    { label: 'Kraftwerk Glatzing-Rüstorf', value: 'Kraftwerk Glatzing-Rüstorf' },
    { label: 'Kraftwerk Haim', value: 'Kraftwerk Haim' },
    { label: 'Kraut E-Werk', value: 'Kraut E-Werk' },
    {
        label: 'Licht- und Kraftstromvertrieb der Gemeinde Opponitz',
        value: 'Licht- und Kraftstromvertrieb der Gemeinde Opponitz',
    },
    {
        label: 'Licht- und Kraftstromvertrieb der Marktgemeinde Göstling an der Ybbs',
        value: 'Licht- und Kraftstromvertrieb der Marktgemeinde Göstling an der Ybbs',
    },
    {
        label: 'Licht- und Kraftvertrieb der Gemeinde Hollenstein an der Ybbs',
        value: 'Licht- und Kraftvertrieb der Gemeinde Hollenstein an der Ybbs',
    },
    {
        label: 'Lichtgenossenschaft Neukirchen',
        value: 'Lichtgenossenschaft Neukirchen',
    },
    { label: 'LINZ NETZ', value: 'LINZ NETZ' },
    {
        label: 'Mag. Engelbert Tassotti E-Werk und EVU',
        value: 'Mag. Engelbert Tassotti E-Werk und EVU',
    },
    {
        label: 'EVU der Marktgemeinde Eibiswald',
        value: 'Marktgemeinde Eibiswald',
    },
    { label: 'Marktgemeinde Neumarkt', value: 'Marktgemeinde Neumarkt' },
    { label: 'Montafonerbahn', value: 'Montafonerbahn' },
    {
        label: 'Murauer Stadtwerke Gesellschaft',
        value: 'Murauer Stadtwerke Gesellschaft',
    },
    { label: 'Netz Burgenland', value: 'netz_burgenland' },
    { label: 'Netz Niederösterreich', value: 'netz_no' },
    { label: 'Netz Oberösterreich', value: 'netz_oberosterreich' },
    { label: 'P.K. Energieversorgung', value: 'P.K. Energieversorgung' },
    { label: 'Polsterer Kerres Ruttin', value: 'Polsterer Kerres Ruttin' },
    {
        label: 'Reverterasches Elektrizitätswerk',
        value: 'Reverterasches Elektrizitätswerk',
    },
    { label: 'Salzburg Netz', value: 'salzburg_netz' },
    {
        label: 'Schwarz, Wagendorffer & Co Elektrizitätswerk',
        value: 'Schwarz, Wagendorffer & Co Elektrizitätswerk',
    },
    { label: 'Stadtbetriebe Mariazell', value: 'Stadtbetriebe Mariazell' },
    { label: 'Stadtwerke Amstetten', value: 'Stadtwerke Amstetten' },
    {
        label: 'Stadtwerke Bruck an der Mur',
        value: 'Stadtwerke Bruck an der Mur',
    },
    { label: 'Stadtwerke Feldkirch', value: 'Stadtwerke Feldkirch' },
    { label: 'Stadtwerke Fürstenfeld', value: 'Stadtwerke Fürstenfeld' },
    {
        label: 'Stadtwerke Hartberg Energieversorgung',
        value: 'Stadtwerke Hartberg Energieversorgung',
    },
    { label: 'Stadtwerke Imst', value: 'Stadtwerke Imst' },
    { label: 'Stadtwerke Judenburg', value: 'Stadtwerke Judenburg' },
    { label: 'Stadtwerke Kapfenberg', value: 'Stadtwerke Kapfenberg' },
    { label: 'Stadtwerke Kitzbühel', value: 'Stadtwerke Kitzbühel' },
    { label: 'Stadtwerke Kufstein', value: 'Stadtwerke Kufstein' },
    { label: 'Stadtwerke Köflach', value: 'Stadtwerke Köflach' },
    { label: 'Stadtwerke Mürzzuschlag', value: 'Stadtwerke Mürzzuschlag' },
    { label: 'Stadtwerke Schwaz', value: 'Stadtwerke Schwaz' },
    {
        label: 'Stadtwerke Trofaiach Gesellschaft',
        value: 'Stadtwerke Trofaiach Gesellschaft',
    },
    { label: 'Stadtwerke Voitsberg', value: 'Stadtwerke Voitsberg' },
    { label: 'Stadtwerke Wörgl', value: 'Stadtwerke Wörgl' },
    { label: 'Stromnetz Graz', value: 'Stromnetz Graz' },
    {
        label: 'Städtische Betriebe Rottenmann',
        value: 'Städtische Betriebe Rottenmann',
    },
    { label: 'TINETZ-Tiroler Netze', value: 'tinetz_tiroler_netze' },
    { label: 'Vorarlberger Energienetze', value: 'Vorarlberger Energienetze' },
    { label: 'Wasserkraft Sölden', value: 'Wasserkraft Sölden' },
    { label: 'Wiener Netze', value: 'wiener_netze' },
    { label: 'Wels Strom GmbH', value: 'Wels Strom GmbH' },
    { label: 'wüsterstrom E-Werk', value: 'wüsterstrom E-Werk' },
];
