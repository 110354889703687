import { Logo } from '@hallosonne/components';
import { Menu } from '@headlessui/react';
import { Divider } from 'components/Atoms/Divider';
import { Wrapper } from 'components/Templates/Wrapper';
import NextLink from 'next/link';

import { TopMenu } from '../TopMenu';

export interface Link {
  href: string;
  title: string;
}

interface TopbarProps {
  links?: Link[];
  ctaButtons?: JSX.Element[];
  loggedIn: boolean;
}

export const Topbar = ({ ctaButtons, loggedIn = false }: TopbarProps) => {
  return (
    <Menu>
      {() => (
        <div className="relative z-10 bg-white-base shadow-none sm:shadow-sm border-b border-auxiliary-200">
          <Wrapper className="pt-3 md:pb-1 pb-0 xl:py-5">
            <div className="flex items-center justify-between mb-2 xl:mb-0">
              <div className="flex items-center gap-6 2xl:gap-8">
                <NextLink href="/" passHref>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

                  <Logo className="w-36 h-6 lg:w-56 lg:h-8 cursor-pointer" />
                </NextLink>
              </div>
              <div className="flex gap-4 items-center">
                {(ctaButtons?.length ?? 0) > 0 && ctaButtons}
              </div>
            </div>
            {loggedIn && (
              <div className="lg:hidden flex flex-col gap-4 mt-4">
                <Divider />
                <TopMenu />
              </div>
            )}
          </Wrapper>
        </div>
      )}
    </Menu>
  );
};
