const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#d3d5da',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  main: '#005358',
  dark: '#00373D',
  light: '#00BABF',
  contrastText: '#fff',
};

const SECONDARY = {
  main: '#feca02',
  dark: '#755C00',
  light: '#FFEF58',
  contrastText: '#fff',
};

const TERTIARY = {
  main: '#919EAB',
  dark: '#212B36',
  light: '#D3D5DA',
  contrastText: '#fff',
};

// const INFO = {
//   lighter: '#CAFDF5',
//   light: '#61F3F3',
//   main: '#00B8D9',
//   dark: '#006C9C',
//   darker: '#003768',
//   contrastText: '#fff',
// };

const SUCCESS = {
  main: '#2e7d32',
  //   lighter: '#D8FBDE',
  //   light: '#86E8AB',
  //   main: '#36B37E',
  //   dark: '#1B806A',
  //   darker: '#0A5554',
  //   contrastText: '#fff',
};

const WARNING = {
  main: '#ed6c02',
};

const ERROR = {
  main: '#d32f2f',
};

export const common = {
  black: '#000',
  white: '#fff',
  primary: PRIMARY,
  secondary: SECONDARY,
  tertiary: TERTIARY,
  //   info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  text: { primary: '#000000DE' },
};
