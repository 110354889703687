var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { hubspotClient } from '../../client';
export const getSpecialDiscountTable = ({ useDraft, } = {}) => __awaiter(void 0, void 0, void 0, function* () {
    const tableId = 'special_discount_table';
    const columns = [
        'id',
        'is_active',
        'name',
        'display_name',
        'type',
        'value',
        'products',
        'product_line',
        'valid_from',
        'valid_until',
        'pv_inverter',
        'es_type',
        'valid_in_provinces',
    ];
    const priceTableRaw = useDraft
        ? yield hubspotClient.cms.hubdb.rowsApi.readDraftTableRows(tableId, undefined, undefined, 10000, columns)
        : yield hubspotClient.cms.hubdb.rowsApi.getTableRows(tableId, undefined, undefined, 10000, columns);
    return priceTableRaw.results.map(({ id, values }) => {
        var _a, _b, _c, _d, _e, _f;
        return ({
            id,
            isActive: !!values.is_active,
            name: values.name,
            displayName: values.display_name,
            type: (_a = values.type) === null || _a === void 0 ? void 0 : _a.name,
            value: values.value,
            products: (_b = values.products) === null || _b === void 0 ? void 0 : _b.map((product) => product.name),
            productLine: (_c = values.product_line) === null || _c === void 0 ? void 0 : _c.name,
            validFrom: values.valid_from ? new Date(values.valid_from) : undefined,
            validUntil: values.valid_until ? new Date(values.valid_until) : undefined,
            pvInverter: (_d = values.pv_inverter) === null || _d === void 0 ? void 0 : _d.map((inverter) => inverter.name),
            esType: (_e = values.es_type) === null || _e === void 0 ? void 0 : _e.map((esType) => esType.name),
            validInProvinces: (_f = values.valid_in_provinces) === null || _f === void 0 ? void 0 : _f.map((province) => province.name),
        });
    });
});
