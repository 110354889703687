export interface DBCompany {
  id: string;
  name: string;
}

export enum InstallationType {
  PV = 'PV',
  ES_RETROFIT = 'ES_RETROFIT',
  PV_ES_BUNDLE = 'PV_ES_BUNDLE',
}

export enum SectionType {
  INDETERMINATE = '0_indeterminate',
  TO_PLAN = '2_to_plan',
  PLANNED = '3_planned',
  IN_PROGRESS = '4_in_progress',
  COMPLETED = '5_completed',
}

export enum ProductLine {
  Flex = 'flex',
  Smart = 'smart',
}

export interface AdditionalEUComponent {
  name: string;
  price: number;
}

export type Installation = {
  id: string;
  type: InstallationType;
  pvId?: string;
  dealId: string;
  roofer?: DBCompany;
  electricalUpgradeRequested: string;
  installationTeam: string[];
  zip: string;
  address: string;
  city: string;
  kwp: string;
  numberOfPanels: string;
  country: string;
  roofInclination?: string;
  dachart: string;
  dacheindeckung: string;
  dealname: string;
  dealStage: string;
  responsiblePm?: string;
  contactPhone?: string;
  numberOfPanelsInstalled: string;
  kwpInstalled: string;
  newSealingRequired?: 'yes' | 'no';
  electricalComponents?: string[];
  electricalComponentsInstalled?: string[];
  electricalComponentOptions?: {
    label: string;
    value: string;
    validatedQuantityProperty?: string;
    installedQuantityProperty?: string;
    validatedQuantity?: string;
    installedQuantity?: string;
    isDisabled?: boolean;
  }[];
  electricalComponentsStatus?: string;
  pvPointOfDeliveryDesignation?: string;
  installationToolkitArray?: 'true' | 'false';
  ownershipSticker?: 'true' | 'false';
  dateOfEnteranceToStage3_5?: string;
  dateOfEnteranceToStage3_6?: string;
  provinceName?: string;
  siteDocumentationApproved?: 'true' | 'false';
  mapsLink?: string;
  capacityOfEnergyStorageValidated: string;
  productLine: string;
  smartInverterRegistrationNumber?: string;
  smartEmailAlias?: string;
  solaxCustomerPassword?: string;
  gridOperator?: string;
  pvSmartInverterValidated?: string;
  pvInverterType?: string;
  energyStorageType?: string;
  selectedInstallationDate?: string;
  selectedInstallationDateRaw?: Date;
  roofinfo?: string;
  section: SectionType;
  contractStatus: string;
  contractOption: string;
  dealProducts: string[];
  batteryInstalledInDifferentRoom?: boolean;
  additionalEUComponentsValidated?: AdditionalEUComponent[];
  additionalEUComponentsInstalled?: AdditionalEUComponent[];
  completeDate?: Date;
  contractSigningDate?: Date;
};

export const isPV = (installation: Installation) =>
  [InstallationType.PV, InstallationType.PV_ES_BUNDLE].includes(
    installation.type,
  );

export const isES = (installation: Installation) =>
  [InstallationType.ES_RETROFIT, InstallationType.PV_ES_BUNDLE].includes(
    installation.type,
  );

export const isBundle = (installation: Installation) =>
  [InstallationType.PV_ES_BUNDLE].includes(installation.type);

export const isFlex = (installation: Installation): boolean =>
  installation.productLine === 'flex' || !installation.productLine;

export const isSmart = (installation: Installation): boolean =>
  installation.productLine === 'smart';

export const isInstallationInPlanning = (installation: Installation) =>
  installation.section === SectionType.TO_PLAN;
export const isInstallationPlanned = (installation: Installation) =>
  installation.section === SectionType.PLANNED;
export const isInstallationInProgress = (installation: Installation) =>
  installation.section === SectionType.IN_PROGRESS;
export const isInstallationComplete = (installation: Installation) =>
  installation.section === SectionType.COMPLETED;
