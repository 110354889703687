import classNames from 'classnames';

interface WrapperProps {
  children: React.ReactNode;
  className?: string;
}

export const Wrapper = ({ children, className }: WrapperProps) => {
  return (
    <div
      className={classNames(
        'w-full max-w-screen-xl mx-auto p-3 bg-white-base',
        className,
      )}
    >
      {children}
    </div>
  );
};
