import { SpinnerOutlineIcon } from '@hallosonne/components';
import { installationResolver } from 'api/client/installationResolver';
import { MessageBox } from 'components/Atoms/MessageBox';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { getDocumentStatuses } from 'services/supabase/adapters/documentMeta';
import {
  getCompanies,
  getDealStages,
  getInstallations,
  getUser,
} from 'services/supabase/adapters/installation';
import { useStore } from 'store/store';
import { useAsyncEffect } from 'use-async-effect';

import { DBUser } from '../services/supabase/adapters/typeUser';

type Props = { children: React.ReactNode };

const App = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const {
    user,
    setUser,
    setInstallations,
    setCompanies,
    setDocumentStatuses,
    setDealStages,
  } = useStore();
  const router = useRouter();
  const pathname = usePathname();

  useAsyncEffect(async () => {
    setLoading(true);
    let loggedUser: DBUser | undefined;
    try {
      loggedUser = await getUser();
    } catch (e) {
      console.log('User not logged in');
    }

    try {
      if (loggedUser) {
        setUser(loggedUser);
        await Promise.all([
          getCompanies().then(setCompanies),
          getDocumentStatuses().then(setDocumentStatuses),
          getDealStages().then(setDealStages),
          getInstallations().then(installations =>
            setInstallations(installations.map(installationResolver)),
          ),
        ]);
      }
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center">
        <SpinnerOutlineIcon className="animate-spin" />
      </div>
    );
  }

  if (!user && pathname !== '/password/recovery' && pathname !== '/login') {
    router.push('/login');
    return <></>;
  }

  if (error) {
    return (
      <MessageBox
        messages={[
          'Es gab einen Fehler beim Laden der Installationen.',
          'Bitte versuch es in ein paar Minuten nochmal.',
        ]}
      />
    );
  }

  return <>{children}</>;
};

export default App;
