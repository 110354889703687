import { omit } from 'lodash';
import { versionToNumber, dateToNumber } from './version';
export var ContractType;
(function (ContractType) {
    ContractType["RENT"] = "rent";
    ContractType["PURCHASE"] = "purchase";
})(ContractType || (ContractType = {}));
export var ProductType;
(function (ProductType) {
    ProductType["PV"] = "photovoltaik";
    ProductType["ES"] = "energy-storage";
})(ProductType || (ProductType = {}));
const isValidPriceInfo = (priceInfo) => versionToNumber(priceInfo.version) > 0 &&
    ((priceInfo.product === ProductType.PV && priceInfo.panels > 0) ||
        (priceInfo.product === ProductType.ES && priceInfo.batteries > 0)) &&
    priceInfo.price > 0 &&
    (priceInfo.type === ContractType.RENT ||
        priceInfo.type === ContractType.PURCHASE);
export const extendPriceList = (key, priceList, priceInfo) => {
    const previousPriceInfo = priceList[key];
    let newPriceInfo = previousPriceInfo !== null && previousPriceInfo !== void 0 ? previousPriceInfo : priceInfo;
    newPriceInfo.versions.push({
        price: priceInfo.price,
        priceWithoutMargin: priceInfo.priceWithoutMargin,
        version: priceInfo.version,
        data: priceInfo.data,
    });
    if (previousPriceInfo) {
        const propertiesToOmit = versionToNumber(priceInfo.version) >
            versionToNumber(previousPriceInfo.version)
            ? ['versions']
            : ['price', 'version', 'versions'];
        newPriceInfo = Object.assign(Object.assign({}, newPriceInfo), omit(priceInfo, propertiesToOmit));
    }
    priceList[key] = newPriceInfo;
    return priceList;
};
export const extendPriceTable = (priceTable, priceInfo) => {
    if (!isValidPriceInfo(priceInfo)) {
        return priceTable;
    }
    const size = priceInfo.product === ProductType.PV
        ? priceInfo.panels
        : priceInfo.batteries;
    extendPriceList(size.toString(), priceTable[priceInfo.type], priceInfo);
    return priceTable;
};
export const getPriceVersionForDate = (priceInfo, dateStr) => {
    const versionNumber = dateToNumber(dateStr);
    const versions = priceInfo.versions.sort((a, b) => versionToNumber(a.version) - versionToNumber(b.version));
    const version = versions.find((element, index, array) => {
        const nextElement = index + 1 < array.length ? array[index + 1] : element;
        const currentVersionNumber = versionToNumber(element.version);
        const nextVersionNumber = versionToNumber(nextElement.version);
        if (versionNumber < currentVersionNumber) {
            return true;
        }
        else if (currentVersionNumber <= versionNumber &&
            versionNumber < nextVersionNumber) {
            return true;
        }
        else if (index + 1 === array.length &&
            currentVersionNumber <= versionNumber) {
            return true;
        }
        return false;
    });
    return version;
};
