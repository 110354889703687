export var EnergyStorageDealStage;
(function (EnergyStorageDealStage) {
    EnergyStorageDealStage["STAGE_0_0"] = "214733555";
    EnergyStorageDealStage["STAGE_1_0"] = "214733556";
    EnergyStorageDealStage["STAGE_1_1"] = "214777583";
    EnergyStorageDealStage["STAGE_1_1_E"] = "214777584";
    EnergyStorageDealStage["STAGE_1_99"] = "214777585";
    EnergyStorageDealStage["STAGE_2_1"] = "214777586";
    EnergyStorageDealStage["STAGE_2_1_E"] = "214777588";
    EnergyStorageDealStage["STAGE_2_2"] = "214777589";
    EnergyStorageDealStage["STAGE_2_2_E"] = "214777590";
    EnergyStorageDealStage["STAGE_2_2_B"] = "214777591";
    EnergyStorageDealStage["STAGE_2_3"] = "214777592";
    EnergyStorageDealStage["STAGE_2_4"] = "214777593";
    EnergyStorageDealStage["STAGE_3_0"] = "214777786";
    EnergyStorageDealStage["STAGE_3_1"] = "234080218";
    EnergyStorageDealStage["STAGE_3_3"] = "234080221";
    EnergyStorageDealStage["STAGE_3_4"] = "234080222";
    EnergyStorageDealStage["STAGE_3_4_E"] = "234080223";
    EnergyStorageDealStage["STAGE_3_5"] = "234080224";
    EnergyStorageDealStage["STAGE_3_5_B"] = "234080225";
    EnergyStorageDealStage["STAGE_3_5_B_E"] = "234080226";
    EnergyStorageDealStage["STAGE_3_6"] = "234080227";
    EnergyStorageDealStage["STAGE_3_6_A"] = "234080228";
    EnergyStorageDealStage["STAGE_3_6_B"] = "234080229";
    EnergyStorageDealStage["STAGE_3_7"] = "234080230";
    EnergyStorageDealStage["STAGE_3_8"] = "234080231";
    EnergyStorageDealStage["STAGE_4_0"] = "234080232";
    EnergyStorageDealStage["STAGE_3_99"] = "234080233";
})(EnergyStorageDealStage || (EnergyStorageDealStage = {}));
export const checkPassedESDealStage = (currentDealStage, targetDealStage, dealStages = undefined) => {
    const stages = dealStages !== null && dealStages !== void 0 ? dealStages : Object.values(EnergyStorageDealStage);
    const currentStageIndex = stages.indexOf(currentDealStage);
    const targetStageIndex = stages.indexOf(targetDealStage);
    return currentStageIndex >= targetStageIndex;
};
