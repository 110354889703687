import {
  CalendarIcon,
  CheckCircleIcon,
  ClockIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export interface TopMenueProsOption {
  title: string;
  link: string;
  icon: JSX.Element;
  active?: boolean;
  enabled: boolean;
}

export const TopMenu: React.FC = () => {
  const [activeViewIndex, setActiveViewIndex] = useState(0);
  const router = useRouter();
  const options = [
    {
      title: 'Zu planen',
      link: '/installationen-zu-planen',
      icon: <PencilSquareIcon className="h-4 w-4" />,
      enabled: true,
    },
    {
      title: 'Geplant',
      link: '/geplante-installationen',
      icon: <CalendarIcon className="w-4 h-4" />,
      enabled: true,
    },
    {
      title: 'In Bearbeitung',
      link: '/installationen-in-installation',
      icon: <ClockIcon className="h-4 w-4" />,
      enabled: true,
    },
    {
      title: 'Abgeschlossen',
      link: '/abgeschlossene-installationen',
      icon: <CheckCircleIcon className="h-4 w-4" />,
      enabled: true,
    },
  ].map((it: TopMenueProsOption, n) => {
    it.active = n === activeViewIndex;

    return it;
  });

  useEffect(() => {
    options.forEach((option, n) => {
      if (router.asPath.includes(option.link) && option.enabled) {
        setActiveViewIndex(n);
      }
    });
  }, [router.asPath, options]);

  return (
    <div className="flex flex-row justify-around w-full">
      {options.map((option, n) => {
        return (
          <button
            key={'option-' + n.toString()}
            className={classNames(
              'flex flex-col items-center text-center w-1/6',
              {
                'opacity-50': !option.active,
                'border-b-2': option.active,
                'border-primary-base': option.active,
                'opacity-20': !option.enabled,
              },
            )}
            onClick={() => void router.push(option.link)}
            disabled={!option.enabled}
          >
            {option.icon}
            <div className="text-[9px] min-[350px]:text-p-xxSmall min-[420px]:text-p-xSmall min-[500px]:text-p-small mt-2 pb-3">
              {option.title}
            </div>
          </button>
        );
      })}
    </div>
  );
};
