import 'normalize.css/normalize.css';
import '@hallosonne/components/dist/style.css';
import 'styles/index.css';

import { Button } from '@hallosonne/components';
import { Topbar } from 'components/Organisms/Topbar';
import { Wrapper } from 'components/Templates/Wrapper';
import config, { IS_IN_PROD } from 'config';
import type { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { SnackbarProvider } from 'notistack';
import { FC } from 'react';
import { useStore } from 'store/store';
import ThemeProvider from 'theme';

import { logout } from '../services/supabase/utils/auth';
import App from './App';

const MyApp: FC<NextAppProps> = ({ Component, pageProps }) => {
  const { setUser, user } = useStore();
  const { gtmId } = config.publicRuntimeConfig.analytics;
  const router = useRouter();

  return (
    <>
      <Head>
        <meta
          name="google-site-verification"
          content="LAM69mTUI532-zS5JzA75s2qzUgPm9QLMK_5F-H4Byk"
        />

        {/* PWA */}
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#ffffff" />

        {!IS_IN_PROD && <meta name="robots" content="noindex" />}
      </Head>
      {IS_IN_PROD && (
        <>
          <Script id="google-analytics" strategy="afterInteractive">
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${gtmId ?? ''}');
          `}
          </Script>
          <Script id="hotjar">
            {`
             (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3582878,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
          </Script>
        </>
      )}
      <noscript>
        <iframe
          title="Google Tag Manager"
          src={`https://www.googletagmanager.com/ns.html?id=${gtmId ?? ''}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      <>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          autoHideDuration={3000}
        >
          <ThemeProvider>
            <Topbar
              ctaButtons={
                user
                  ? [
                      <Button
                        key="signout"
                        icon="LogoutIcon"
                        iconSize="xxSmallResponsive"
                        variant="alternate"
                        onClick={() =>
                          logout().then(() => {
                            setUser(undefined);
                            router.push('/login');
                          })
                        }
                      >
                        Abmelden
                      </Button>,
                    ]
                  : []
              }
              loggedIn={!!user}
            />
            <Wrapper>
              <App>
                <Component {...pageProps} />
              </App>
            </Wrapper>
          </ThemeProvider>
        </SnackbarProvider>
      </>
    </>
  );
};

export default MyApp;
