import {
  HubspotDeal,
  HubspotInstallerNotes,
  HubspotNote,
} from '@hallosonne/hubspot-api-client';
import { FileData } from '@hallosonne/types';
import { DBCompany, Installation, InstallationType } from 'api/types';
import { AxiosResponse } from 'axios';
import { merge } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import useSWR from 'swr';
import { useAsyncEffect } from 'use-async-effect';
import { axiosClient } from 'utils/api/axios-client';

import { updateInstallationDate } from '../../../api';
import { DBUser } from './typeUser';

export const getUser = async (): Promise<DBUser> => {
  const axiosInstance = await axiosClient();
  const userResponse = await axiosInstance.get<DBUser>('/api/user');
  return userResponse.data;
};

export const getCompanies = async (): Promise<DBCompany[]> => {
  const axiosInstance = await axiosClient();
  const companyResponse = await axiosInstance.get<DBCompany[]>(
    '/api/companies',
  );
  return companyResponse.data;
};

export const updateDealInstallationDate = async (
  dealId: string,
  installationDate: Date,
  oldInstallationDate?: Date,
  changeReason?: string,
  installationTeam?: string,
): Promise<string> =>
  updateInstallationDate(
    `/api/deals/${dealId}/updateInstallationDate`,
    installationDate,
    oldInstallationDate,
    changeReason,
    installationTeam,
  );

export const updateDealProperties = async (
  dealId: string,
  properties: Partial<HubspotDeal>,
) => {
  try {
    const axiosInstance = await axiosClient();
    const updateResponse: AxiosResponse<string> = await axiosInstance.put(
      `/api/deals/${dealId}/properties`,
      {
        properties,
      },
    );
    return updateResponse.data;
  } catch (error) {
    console.log(error);
  }
};

export const getInstallations = async (): Promise<Installation[]> => {
  const axiosInstance = await axiosClient();
  const installations = await axiosInstance.get<Installation[]>(
    '/api/installations',
  );
  return installations.data;
};

export const getDealStages = async (): Promise<string[]> => {
  const axiosInstance = await axiosClient();
  const dealStages = await axiosInstance.get<string[]>('/api/deals/dealStages');
  return dealStages.data;
};

export const useHubspotPVNotes = (id?: string) =>
  useSWR(id ? `/api/deals/${id}/notes` : null, async (uri: string) => {
    const response = await axiosFetch<HubspotInstallerNotes>(uri);
    return response?.data;
  });

export const getHubspotNotesByDealId = async (
  dealId: string,
): Promise<HubspotInstallerNotes> => {
  const axiosInstance = await axiosClient();
  const notes: AxiosResponse<HubspotInstallerNotes> = await axiosInstance.get(
    `/api/deals/${dealId}/notes`,
  );

  return notes.data;
};

export const useHubspotNoteFiles = (
  installationId: string | undefined,
  notes: HubspotNote[] | undefined,
): [FileData[], boolean, Dispatch<SetStateAction<boolean>>] => {
  const [filesLoaded, setFilesLoaded] = useState(false);
  const [files, setFiles] = useState<Record<string, FileData>>({});

  const noteIdByFileId = new Map<string, string>();
  notes?.forEach(note => {
    const fileIds = note.properties.hs_attachment_ids?.split(';');
    fileIds?.forEach(fid => noteIdByFileId.set(fid, note.id));
  });

  const fileIds =
    notes?.flatMap(
      ({ properties }) =>
        properties.hs_attachment_ids?.split(';').filter(id => id !== '') ?? [],
    ) ?? [];

  useAsyncEffect(async () => {
    if (notes && installationId) {
      const fileIdsToLoad = fileIds.filter(fileId => !files[fileId]);
      if (fileIdsToLoad.length) {
        setFilesLoaded(false);

        const newFilesResults = await Promise.allSettled(
          fileIdsToLoad.map(fileId => getFileByFileId(fileId, installationId)),
        );
        const newFiles = (
          newFilesResults.filter(
            ({ status }) => status === 'fulfilled',
          ) as PromiseFulfilledResult<FileData>[]
        ).map(({ value }) => value);

        setFiles(
          merge(
            files,
            ...newFiles
              .filter(file => !!file)
              .map(file => ({
                [file.id]: {
                  ...file,
                  noteId: noteIdByFileId.get(file.id),
                },
              })),
          ),
        );

        setFilesLoaded(true);
      } else {
        setFilesLoaded(true);
      }
    }
  }, [notes, installationId, files, setFilesLoaded, setFiles]);

  return [
    Object.values(files).filter(file => fileIds.includes(file.id)),
    filesLoaded,
    setFilesLoaded,
  ];
};

export const getFileByFileId = async (
  fileId: string,
  installationId: string,
): Promise<FileData> => {
  if (!fileId?.length || !installationId?.length) {
    return Promise.reject('getFileByFileId: no fileId or installationId set');
  }

  const axiosInstance = await axiosClient();
  const file: AxiosResponse<FileData> = await axiosInstance.get(
    `/api/installation/${installationId}/files`,
    { params: { fileId } },
  );

  return file.data;
};

export const uploadFile = async (
  documentType: string,
  dealId: string,
  file: File,
  name: string,
): Promise<string[]> => {
  const data = {
    dealId,
    documentType,
    file,
    name,
  };

  return uploadToPV(data, dealId);
};

const uploadToPV = async (
  data: Record<string, string | File | InstallationType>,
  dealId: string,
) => {
  return upload(`/api/deals/${dealId}/files`, createFormData(data));
};

const upload = async (url: string, data: FormData) => {
  const axiosInstance = await axiosClient();
  const files: AxiosResponse<{ resolvedUploadedFileIds: string[] }> =
    await axiosInstance.post(url, data);

  return files.data.resolvedUploadedFileIds;
};

export const axiosFetch = async <T>(
  uri: string,
): Promise<void | AxiosResponse<T>> => {
  const axiosInstance = await axiosClient();
  return await axiosInstance.get<T>(uri).catch(error => {
    console.error(error.response.data.message);
  });
};

const createFormData = (
  data: Record<string, string | File | InstallationType>,
) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));

  return formData;
};
