import { hubspotClient } from '../../client';
export const getDealByVecId = (vecId) => hubspotClient.crm.deals.searchApi.doSearch({
    filterGroups: [
        {
            filters: [
                {
                    propertyName: 'vec_id',
                    operator: 'EQ',
                    value: vecId,
                },
            ],
        },
    ],
    properties: [
        'hs_object_id',
        'vec_id',
        'dealstage',
        'total_purchase_price_installed_excl_vat_incl_discount',
        'pv_power_installed',
        'es_type_capacity_installed',
    ],
    limit: 1,
    after: 0,
    sorts: [],
});
