var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
export const createNote = (noteBody, dealId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axios.post('https://api.hubapi.com/engagements/v1/engagements', {
        engagement: {
            active: true,
            type: 'NOTE',
        },
        associations: {
            contactIds: [],
            companyIds: [],
            dealIds: dealId ? [dealId] : [],
            ownerIds: [],
        },
        metadata: {
            body: noteBody,
        },
    }, {
        headers: {
            Authorization: `Bearer ${process.env.HUBSPOT_ACCESS_TOKEN}`,
            'Content-Type': 'application/json',
        },
    });
    return response.data.engagement.id;
});
