var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { uploadFileList } from '../file/uploadFileList';
import { addFilesToNote } from '../note/addFilesToNote';
import { InstallerNoteBody } from '../note/notesToInstallerNotes';
import { createDealNote } from './createDealNote';
import { createDealSubfolder } from './createDealSubfolder';
import { getInstallerNotes } from './getInstallerNotes';
import { updateDealProperties } from './updateDealProperties';
import { DocumentNames, HubspotPhotoUploadSubFolders, HubspotImageUploadStatus, } from '../../types';
const getImageStatusPropertyNameByImageUploadName = (name) => {
    switch (name) {
        case HubspotPhotoUploadSubFolders.ROOF:
            return 'roof_images_status';
        case HubspotPhotoUploadSubFolders.ROOF_CLOSE_UP:
            return 'roof_close_up_images_status';
        case HubspotPhotoUploadSubFolders.BUILDING:
            return 'building_images_status';
        case HubspotPhotoUploadSubFolders.ELECTRICITY_METER:
            return 'electricity_meter_images_status';
        case HubspotPhotoUploadSubFolders.CONNECTION_BOX:
            return 'connection_box_images_status';
        case HubspotPhotoUploadSubFolders.HOUSE_CONNECTION_BOX:
            return 'house_connection_box_images_status';
        default:
            return null;
    }
};
export const uploadInstallerFiles = (parsedForm) => __awaiter(void 0, void 0, void 0, function* () {
    const { dealId, fileList, documentType } = parsedForm;
    const isPhotoUpload = documentType === DocumentNames.ClientPhotos;
    const isDocumentation = !isPhotoUpload;
    const folderId = yield createDealSubfolder(dealId, documentType);
    const notesKey = isPhotoUpload ? 'photos' : 'documentation';
    let installerNotes = yield getInstallerNotes(dealId);
    if (!(installerNotes === null || installerNotes === void 0 ? void 0 : installerNotes[notesKey]) || !(installerNotes === null || installerNotes === void 0 ? void 0 : installerNotes[notesKey].length)) {
        yield createDealNote(dealId, isPhotoUpload ? InstallerNoteBody.FOTOS : InstallerNoteBody.DOCUMENTATION);
        installerNotes = yield getInstallerNotes(dealId);
    }
    const notes = installerNotes === null || installerNotes === void 0 ? void 0 : installerNotes[notesKey];
    const resolvedUploadedFileIds = yield uploadFileList(fileList, folderId, isDocumentation ? documentType : undefined, isPhotoUpload || documentType === DocumentNames.PV_AttachedPhotos);
    yield fileList.forEach(({ name }) => __awaiter(void 0, void 0, void 0, function* () {
        if (isPhotoUpload) {
            const uploadStatusProperty = getImageStatusPropertyNameByImageUploadName(name);
            if (uploadStatusProperty) {
                yield updateDealProperties(dealId, {
                    [uploadStatusProperty]: HubspotImageUploadStatus.IN_VALIDATION,
                });
            }
        }
    }));
    if (notes === null || notes === void 0 ? void 0 : notes.length) {
        yield addFilesToNote(resolvedUploadedFileIds, notes[0]);
    }
    return resolvedUploadedFileIds;
});
